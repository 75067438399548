import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Breadcrumb from "../components/BreadCrumb";
import Content, { HTMLContent } from "../components/Content";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export const AboutPageTemplate = ({ image, classes, header, aboutcontent }) => {
  return (
    <div className="content">
      <div className="container">
        <Grid justify="space-between" container className="about-container">
          <Grid item md={6} xs={12}>
            <div className="about-content">
              <Breadcrumb page="Um fyrirtækið"></Breadcrumb>
              <h2>{header}</h2>
              <div dangerouslySetInnerHTML={{ __html: aboutcontent }} />
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <div>
              <div
                className="about-image"
                style={{
                  backgroundImage: `url(${
                    !!image.childImageSharp
                      ? image.childImageSharp.fluid.src
                      : image
                  })`,
                }}
              ></div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const classes = useStyles();
  return (
    <Layout>
      <AboutPageTemplate
        classes={classes}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        aboutcontent={post.frontmatter.aboutcontent}
        header={post.frontmatter.header}
        image={post.frontmatter.image}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        aboutcontent
        header
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
