import React from 'react';
import { Link } from "gatsby";
import Arrow from "../img/icons/breadcrumb-arrow.svg"

function handleClick(event) {
}

export default function BreadCrumb(props) {
  return (
    <div className="breadcrumbs">
        <Link to="/" onClick={handleClick}>
            Forsíða
        </Link> <Arrow />
        <p> {props.page}</p>
    </div>
  );
}